import { FMPayPaginationQuery, FMPayResponse, FMPayResponsePagination } from "fp/store/type";
import {
  CreateDTO,
  FMPayBankAccountReconciliationModel,
  PaginationQuery,
  PreviousWorkSchedulingQuery,
  WorkSchedulingQuery
} from "fp/modules/bank-account-reconciliation/type";
import { HttpClient } from "fp/store/HttpClient";
import { FMPayWorkSchedulingPercentageModel } from "fp/modules/work-scheduling-percentage/type";

export class Service {
  public static async findById(
    id: string
  ): Promise<FMPayResponse<FMPayBankAccountReconciliationModel>> {
    return await HttpClient.Get(`/v1/bank/account/reconciliation/${id}`);
  }

  public static async deleteByIds(ids: string[]): Promise<FMPayResponse<boolean>> {
    return await HttpClient.Post(`/v1/bank/account/reconciliation/deleted`, { ids });
  }

  public static async lockById(
    id: string
  ): Promise<FMPayResponse<FMPayBankAccountReconciliationModel>> {
    return await HttpClient.Post(`/v1/bank/account/reconciliation/lock/${id}`, {});
  }

  public static async checkedByWorkScheduling(
    workSchedulingOperationId: string
  ): Promise<FMPayResponse<FMPayWorkSchedulingPercentageModel[]>> {
    return await HttpClient.Post(
      `/v1/bank/account/reconciliation/checked/${workSchedulingOperationId}`,
      {}
    );
  }

  public static async pagination(
    query: FMPayPaginationQuery<Partial<PaginationQuery>>
  ): Promise<FMPayResponsePagination<FMPayBankAccountReconciliationModel>> {
    return await HttpClient.Post(`/v1/bank/account/reconciliation/pagination`, query);
  }

  // 计算某个班次所有银行的预计余额
  // 由于数据太大，不适合在sql中直接计算
  // 预计余额=银行上个班次的剩余余额-本班次该银行已支付的总额
  public static async paginationByWorkSchedulingOperation(
    query: FMPayPaginationQuery<Partial<WorkSchedulingQuery>>
  ): Promise<FMPayResponsePagination<FMPayBankAccountReconciliationModel>> {
    return await HttpClient.Post(
      `/v1/bank/account/reconciliation/pagination/by/work/scheduling/operation`,
      query
    );
  }

  // 上个班次所有银行的记录
  public static async previous(
    query: FMPayPaginationQuery<Partial<PreviousWorkSchedulingQuery>>
  ): Promise<FMPayResponsePagination<FMPayBankAccountReconciliationModel>> {
    return await HttpClient.Post(`/v1/bank/account/reconciliation/previous`, query);
  }

  public static async update(
    model: FMPayBankAccountReconciliationModel
  ): Promise<FMPayResponse<FMPayBankAccountReconciliationModel>> {
    return await HttpClient.Post(`/v1/bank/account/reconciliation/update`, model);
  }

  public static async create(
    dto: CreateDTO
  ): Promise<FMPayResponse<FMPayBankAccountReconciliationModel>> {
    return await HttpClient.Post(`/v1/bank/account/reconciliation/add`, dto);
  }
}
