import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Service } from "fp/modules/ngnpay-account/Service";
import {
  CreateDTO,
  FMPayNGNPayAccountModel,
  PaginationQuery
} from "fp/modules/ngnpay-account/type";
import { FMPayPagination, FMPayPaginationQuery } from "fp/store/type";

export const ngnpayAccountFindById = createAsyncThunk<FMPayNGNPayAccountModel, string>(
  "ngnpay.account/find.by.id",
  async (id: string) => {
    const resp = await Service.findById(id);
    return resp.data;
  }
);

export const ngnpayAccountDeleteByIds = createAsyncThunk<boolean, string[]>(
  "ngnpay.account/delete.by.ids",
  async (ids: string[]) => {
    const resp = await Service.deleteByIds(ids);
    return resp.data;
  }
);

export const ngnpayAccountPagination = createAsyncThunk<
  FMPayPagination<FMPayNGNPayAccountModel>,
  FMPayPaginationQuery<Partial<PaginationQuery>>
>("ngnpay.account/pagination", async (query: FMPayPaginationQuery<Partial<PaginationQuery>>) => {
  const resp = await Service.pagination(query);
  return resp.data;
});

export const ngnpayAccountUpdate = createAsyncThunk<
  FMPayNGNPayAccountModel,
  FMPayNGNPayAccountModel
>("ngnpay.account/update", async (model: FMPayNGNPayAccountModel) => {
  const resp = await Service.update(model);
  return resp.data;
});

export const ngnpayAccountCreate = createAsyncThunk<FMPayNGNPayAccountModel, CreateDTO>(
  "ngnpay.account/create",
  async (dto: CreateDTO) => {
    const resp = await Service.create(dto);
    return resp.data;
  }
);

export interface NGNPayAccountState {
  model: FMPayNGNPayAccountModel | null;
  models: FMPayPagination<FMPayNGNPayAccountModel>;
  loading: {
    findById: boolean;
    deleted: string[];
    pagination: boolean;
    update: string;
    create: boolean;
  };
}

const InitialState: NGNPayAccountState = {
  model: null,
  models: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  loading: {
    findById: false,
    deleted: [],
    pagination: false,
    update: "",
    create: false
  }
};

export const ngnpayAccountSlice = createSlice({
  name: "ngnpayAccount",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(ngnpayAccountFindById.pending, (state) => {
      state.loading.findById = true;
    });
    builder.addCase(ngnpayAccountFindById.rejected, (state) => {
      state.loading.findById = false;
    });
    builder.addCase(ngnpayAccountFindById.fulfilled, (state, action) => {
      state.loading.findById = false;
      state.model = action.payload;
    });

    builder.addCase(ngnpayAccountDeleteByIds.pending, (state, action) => {
      state.loading.deleted = action.meta.arg;
    });
    builder.addCase(ngnpayAccountDeleteByIds.rejected, (state) => {
      state.loading.deleted = [];
    });
    builder.addCase(ngnpayAccountDeleteByIds.fulfilled, (state) => {
      state.loading.deleted = [];
    });

    builder.addCase(ngnpayAccountUpdate.pending, (state, action) => {
      state.loading.update = action.meta.arg.id;
    });
    builder.addCase(ngnpayAccountUpdate.rejected, (state) => {
      state.loading.update = "";
    });
    builder.addCase(ngnpayAccountUpdate.fulfilled, (state, action) => {
      state.loading.update = "";
      state.model = action.payload;
    });

    builder.addCase(ngnpayAccountPagination.pending, (state) => {
      state.loading.pagination = true;
    });
    builder.addCase(ngnpayAccountPagination.rejected, (state) => {
      state.loading.pagination = false;
    });
    builder.addCase(ngnpayAccountPagination.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.models = action.payload;
    });

    builder.addCase(ngnpayAccountCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(ngnpayAccountCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(ngnpayAccountCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });
  }
});

export default ngnpayAccountSlice.reducer;
