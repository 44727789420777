import { useAppSelector } from "fp/store/hooks";
import { UserRole, UserRoleName } from "fp/store/type";
import { FunctionComponent, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { Typography } from "antd";

const UserRoleValue = {
  [UserRoleName.STAFF]: UserRole.STAFF,
  [UserRoleName.FINANCIAL]: UserRole.FINANCIAL,
  [UserRoleName.MANAGER]: UserRole.MANAGER,
  [UserRoleName.ADMIN]: UserRole.ADMIN
};

const UserRoleNameMap = {
  [UserRole.STAFF]: UserRoleName.STAFF,
  [UserRole.FINANCIAL]: UserRoleName.FINANCIAL,
  [UserRole.MANAGER]: UserRoleName.MANAGER,
  [UserRole.ADMIN]: UserRoleName.ADMIN
};

export function getUserRoleLabel(role: UserRoleName) {
  if (role === UserRoleName.ADMIN) {
    return "系统管理员";
  } else if (role === UserRoleName.MANAGER) {
    return "管理员";
  } else if (role === UserRoleName.FINANCIAL) {
    return "财务";
  } else if (role === UserRoleName.STAFF) {
    return "用户";
  } else {
    return "未知角色";
  }
}

export function getUserRoleLabelByValue(role: UserRole) {
  return getUserRoleLabel(UserRoleNameMap[role]);
}

export function checkUserRole(role: UserRole, userRole: UserRoleName) {
  const a: number = UserRoleValue[userRole];
  return a >= role;
}

export interface AuthorizationProps {
  children: ReactNode;
  role: UserRole;
  showMessage?: boolean;
  strict?: boolean;
}

export const Authorization: FunctionComponent<AuthorizationProps> = function (
  props: AuthorizationProps
): ReactNode {
  const user = useAppSelector((state) => state.sign.user);

  if (user.id === "") {
    return <Navigate to="/login" />;
  }

  if (props.strict) {
    if (props.role === UserRoleValue[user.role]) {
      return props.children;
    }

    if (props.showMessage) {
      return <Typography.Text type="danger">无权限访问</Typography.Text>;
    }

    return null;
  }

  if (checkUserRole(props.role, user.role)) {
    return props.children;
  }

  if (props.showMessage) {
    return <Typography.Text type="danger">无权限访问</Typography.Text>;
  }

  return null;
};
