import { FMPayPaginationQuery, FMPayResponse, FMPayResponsePagination } from "fp/store/type";
import {
  CreateDTO,
  FMPayWorkTimePlanningModel,
  PaginationQuery
} from "fp/modules/work-time-planning/type";
import { HttpClient } from "fp/store/HttpClient";

export class Service {
  public static async findById(id: string): Promise<FMPayResponse<FMPayWorkTimePlanningModel>> {
    return await HttpClient.Get(`/v1/work/time/planning/${id}`);
  }

  public static async deleteByIds(ids: string[]): Promise<FMPayResponse<boolean>> {
    return await HttpClient.Post(`/v1/work/time/planning/deleted`, { ids });
  }

  public static async active(id: string): Promise<FMPayResponse<FMPayWorkTimePlanningModel>> {
    return await HttpClient.Post(`/v1/work/time/planning/active/${id}`);
  }

  public static async disable(id: string): Promise<FMPayResponse<FMPayWorkTimePlanningModel>> {
    return await HttpClient.Post(`/v1/work/time/planning/disable/${id}`);
  }

  public static async pagination(
    query: FMPayPaginationQuery<Partial<PaginationQuery>>
  ): Promise<FMPayResponsePagination<FMPayWorkTimePlanningModel>> {
    return await HttpClient.Post(`/v1/work/time/planning/pagination`, query);
  }

  public static async today(): Promise<FMPayResponse<FMPayWorkTimePlanningModel[]>> {
    return await HttpClient.Post(`/v1/work/time/planning/today`);
  }

  public static async update(
    model: FMPayWorkTimePlanningModel
  ): Promise<FMPayResponse<FMPayWorkTimePlanningModel>> {
    return await HttpClient.Post(`/v1/work/time/planning/update`, model);
  }

  public static async create(dto: CreateDTO): Promise<FMPayResponse<FMPayWorkTimePlanningModel>> {
    return await HttpClient.Post(`/v1/work/time/planning/add`, dto);
  }
}
