import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Service } from "fp/modules/normal-bank/Service";
import { CreateDTO, FMPayNormalBankModel, PaginationQuery } from "fp/modules/normal-bank/type";
import { FMPayPagination, FMPayPaginationQuery } from "fp/store/type";

export const normalBankFindById = createAsyncThunk<FMPayNormalBankModel, string>(
  "normal.bank/find.by.id",
  async (id: string) => {
    const resp = await Service.findById(id);
    return resp.data;
  }
);

export const normalBankDeleteByIds = createAsyncThunk<boolean, string[]>(
  "normal.bank/delete.by.ids",
  async (ids: string[]) => {
    const resp = await Service.deleteByIds(ids);
    return resp.data;
  }
);

export const normalBankPagination = createAsyncThunk<
  FMPayPagination<FMPayNormalBankModel>,
  FMPayPaginationQuery<Partial<PaginationQuery>>
>("normal.bank/pagination", async (query: FMPayPaginationQuery<Partial<PaginationQuery>>) => {
  const resp = await Service.pagination(query);
  return resp.data;
});

export const normalBankUpdate = createAsyncThunk<FMPayNormalBankModel, FMPayNormalBankModel>(
  "normal.bank/update",
  async (model: FMPayNormalBankModel) => {
    const resp = await Service.update(model);
    return resp.data;
  }
);

export const normalBankCreate = createAsyncThunk<FMPayNormalBankModel, CreateDTO>(
  "normal.bank/create",
  async (dto: CreateDTO) => {
    const resp = await Service.create(dto);
    return resp.data;
  }
);

export interface NormalBankState {
  model: FMPayNormalBankModel | null;
  models: FMPayPagination<FMPayNormalBankModel>;
  loading: {
    findById: boolean;
    deleted: string[];
    pagination: boolean;
    update: string;
    create: boolean;
  };
}

const InitialState: NormalBankState = {
  model: null,
  models: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  loading: {
    findById: false,
    deleted: [],
    pagination: false,
    update: "",
    create: false
  }
};

export const normalBankSlice = createSlice({
  name: "normalBank",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(normalBankFindById.pending, (state) => {
      state.loading.findById = true;
    });
    builder.addCase(normalBankFindById.rejected, (state) => {
      state.loading.findById = false;
    });
    builder.addCase(normalBankFindById.fulfilled, (state, action) => {
      state.loading.findById = false;
      state.model = action.payload;
    });

    builder.addCase(normalBankDeleteByIds.pending, (state, action) => {
      state.loading.deleted = action.meta.arg;
    });
    builder.addCase(normalBankDeleteByIds.rejected, (state) => {
      state.loading.deleted = [];
    });
    builder.addCase(normalBankDeleteByIds.fulfilled, (state) => {
      state.loading.deleted = [];
    });

    builder.addCase(normalBankUpdate.pending, (state, action) => {
      state.loading.update = action.meta.arg.id;
    });
    builder.addCase(normalBankUpdate.rejected, (state) => {
      state.loading.update = "";
    });
    builder.addCase(normalBankUpdate.fulfilled, (state, action) => {
      state.loading.update = "";
      state.model = action.payload;
    });

    builder.addCase(normalBankPagination.pending, (state) => {
      state.loading.pagination = true;
    });
    builder.addCase(normalBankPagination.rejected, (state) => {
      state.loading.pagination = false;
    });
    builder.addCase(normalBankPagination.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.models = action.payload;
    });

    builder.addCase(normalBankCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(normalBankCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(normalBankCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });
  }
});

export default normalBankSlice.reducer;
