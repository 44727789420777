import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Service } from "fp/modules/work-scheduling/Service";
import {
  CreateDTO,
  FMPayWorkSchedulingModel,
  PaginationQuery
} from "fp/modules/work-scheduling/type";
import { FMPayPagination, FMPayPaginationQuery } from "fp/store/type";

export const workSchedulingFindById = createAsyncThunk<FMPayWorkSchedulingModel, string>(
  "work.scheduling/find.by.id",
  async (id: string) => {
    const resp = await Service.findById(id);
    return resp.data;
  }
);

export const workSchedulingDeleteByIds = createAsyncThunk<boolean, string[]>(
  "work.scheduling/delete.by.ids",
  async (ids: string[]) => {
    const resp = await Service.deleteByIds(ids);
    return resp.data;
  }
);

export const workSchedulingPagination = createAsyncThunk<
  FMPayPagination<FMPayWorkSchedulingModel>,
  FMPayPaginationQuery<Partial<PaginationQuery>>
>("work.scheduling/pagination", async (query: FMPayPaginationQuery<Partial<PaginationQuery>>) => {
  const resp = await Service.pagination(query);
  return resp.data;
});

export const workSchedulingTodayPagination = createAsyncThunk<
  FMPayPagination<FMPayWorkSchedulingModel>,
  FMPayPaginationQuery<Partial<PaginationQuery>>
>(
  "work.scheduling/today.pagination",
  async (query: FMPayPaginationQuery<Partial<PaginationQuery>>) => {
    const resp = await Service.today(query);
    return resp.data;
  }
);

export const workSchedulingUpdate = createAsyncThunk<
  FMPayWorkSchedulingModel,
  FMPayWorkSchedulingModel
>("work.scheduling/update", async (model: FMPayWorkSchedulingModel) => {
  const resp = await Service.update(model);
  return resp.data;
});

export const workSchedulingCreate = createAsyncThunk<FMPayWorkSchedulingModel, CreateDTO>(
  "work.scheduling/create",
  async (dto: CreateDTO) => {
    const resp = await Service.create(dto);
    return resp.data;
  }
);

export interface WorkSchedulingState {
  model: FMPayWorkSchedulingModel | null;
  models: FMPayPagination<FMPayWorkSchedulingModel>;
  today: FMPayPagination<FMPayWorkSchedulingModel>;
  loading: {
    findById: boolean;
    deleted: string[];
    pagination: boolean;
    today: boolean;
    update: string;
    create: boolean;
  };
}

const InitialState: WorkSchedulingState = {
  model: null,
  models: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  today: {
    total: 0,
    size: 999,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  loading: {
    findById: false,
    deleted: [],
    pagination: false,
    today: false,
    update: "",
    create: false
  }
};

export const workSchedulingSlice = createSlice({
  name: "workScheduling",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(workSchedulingFindById.pending, (state) => {
      state.loading.findById = true;
    });
    builder.addCase(workSchedulingFindById.rejected, (state) => {
      state.loading.findById = false;
    });
    builder.addCase(workSchedulingFindById.fulfilled, (state, action) => {
      state.loading.findById = false;
      state.model = action.payload;
    });

    builder.addCase(workSchedulingDeleteByIds.pending, (state, action) => {
      state.loading.deleted = action.meta.arg;
    });
    builder.addCase(workSchedulingDeleteByIds.rejected, (state) => {
      state.loading.deleted = [];
    });
    builder.addCase(workSchedulingDeleteByIds.fulfilled, (state) => {
      state.loading.deleted = [];
    });

    builder.addCase(workSchedulingUpdate.pending, (state, action) => {
      state.loading.update = action.meta.arg.id;
    });
    builder.addCase(workSchedulingUpdate.rejected, (state) => {
      state.loading.update = "";
    });
    builder.addCase(workSchedulingUpdate.fulfilled, (state, action) => {
      state.loading.update = "";
      state.model = action.payload;
    });

    builder.addCase(workSchedulingPagination.pending, (state) => {
      state.loading.pagination = true;
    });
    builder.addCase(workSchedulingPagination.rejected, (state) => {
      state.loading.pagination = false;
    });
    builder.addCase(workSchedulingPagination.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.models = action.payload;
    });

    builder.addCase(workSchedulingTodayPagination.pending, (state) => {
      state.loading.today = true;
    });
    builder.addCase(workSchedulingTodayPagination.rejected, (state) => {
      state.loading.today = false;
    });
    builder.addCase(workSchedulingTodayPagination.fulfilled, (state, action) => {
      state.loading.today = false;
      state.today = action.payload;
    });

    builder.addCase(workSchedulingCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(workSchedulingCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(workSchedulingCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });
  }
});

export default workSchedulingSlice.reducer;
