import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Decimal from "decimal.js";
import { Service } from "fp/modules/bank-account-reconciliation/Service";
import { Service as WorkSchedulingOperationService } from "fp/modules/work-scheduling-operation/Service";
import {
  CreateDTO,
  FMPayBankAccountReconciliationModel,
  PaginationQuery,
  PreviousWorkSchedulingQuery,
  WorkSchedulingQuery
} from "fp/modules/bank-account-reconciliation/type";
import { FMPayPagination, FMPayPaginationQuery } from "fp/store/type";
import { FMPayWorkSchedulingOperationModel } from "fp/modules/work-scheduling-operation/type";
import { FMPayWorkSchedulingPercentageModel } from "fp/modules/work-scheduling-percentage/type";

export const bankAccountReconciliationFindById = createAsyncThunk<
  FMPayBankAccountReconciliationModel,
  string
>("bank.account.reconciliation/find.by.id", async (id: string) => {
  const resp = await Service.findById(id);
  return resp.data;
});

export const bankAccountReconciliationDeleteByIds = createAsyncThunk<boolean, string[]>(
  "bank.account.reconciliation/delete.by.ids",
  async (ids: string[]) => {
    const resp = await Service.deleteByIds(ids);
    return resp.data;
  }
);

export const bankAccountReconciliationLockById = createAsyncThunk<
  FMPayBankAccountReconciliationModel,
  string
>("bank.account.reconciliation/lock.by.ids", async (id: string) => {
  const resp = await Service.lockById(id);
  return resp.data;
});

export const bankAccountReconciliationCheckedByWorkScheduling = createAsyncThunk<
  FMPayWorkSchedulingPercentageModel[],
  string
>("bank.account.reconciliation/checkedByWorkScheduling", async (id: string) => {
  const resp = await Service.checkedByWorkScheduling(id);
  return resp.data;
});

export const bankAccountReconciliationLockWorkSchedulingOperationById = createAsyncThunk<
  FMPayWorkSchedulingOperationModel,
  string
>("bank.account.reconciliation/lock.work.scheduling.operation.by.ids", async (id: string) => {
  const resp = await WorkSchedulingOperationService.lockById(id);
  return resp.data;
});

export const bankAccountReconciliationQueryUsers = createAsyncThunk<
  FMPayWorkSchedulingOperationModel[],
  string
>("bank.account.reconciliation/query.users", async (workSchedulingOperationId: string) => {
  const resp = await WorkSchedulingOperationService.allById(workSchedulingOperationId);
  return resp.data;
});

export const bankAccountReconciliationPagination = createAsyncThunk<
  FMPayPagination<FMPayBankAccountReconciliationModel>,
  FMPayPaginationQuery<Partial<PaginationQuery>>
>(
  "bank.account.reconciliation/pagination",
  async (query: FMPayPaginationQuery<Partial<PaginationQuery>>) => {
    const resp = await Service.pagination(query);
    return resp.data;
  }
);

export const bankAccountReconciliationPaginationByWSO = createAsyncThunk<
  FMPayPagination<FMPayBankAccountReconciliationModel>,
  FMPayPaginationQuery<Partial<WorkSchedulingQuery>>
>(
  "bank.account.reconciliation/pagination.by.work.scheduling",
  async (query: FMPayPaginationQuery<Partial<WorkSchedulingQuery>>) => {
    const resp = await Service.paginationByWorkSchedulingOperation(query);
    return resp.data;
  }
);

export const bankAccountReconciliationPrevious = createAsyncThunk<
  FMPayPagination<FMPayBankAccountReconciliationModel>,
  FMPayPaginationQuery<Partial<PreviousWorkSchedulingQuery>>
>(
  "bank.account.reconciliation/previous",
  async (query: FMPayPaginationQuery<Partial<PreviousWorkSchedulingQuery>>) => {
    const resp = await Service.previous(query);
    return resp.data;
  }
);

export const bankAccountReconciliationUpdate = createAsyncThunk<
  FMPayBankAccountReconciliationModel,
  FMPayBankAccountReconciliationModel
>("bank.account.reconciliation/update", async (model: FMPayBankAccountReconciliationModel) => {
  const resp = await Service.update(model);
  return resp.data;
});

export const bankAccountReconciliationCreate = createAsyncThunk<
  FMPayBankAccountReconciliationModel,
  CreateDTO
>("bank.account.reconciliation/create", async (dto: CreateDTO) => {
  const resp = await Service.create(dto);
  return resp.data;
});

export interface BankAccountReconciliationState {
  model: FMPayBankAccountReconciliationModel | null;
  models: FMPayPagination<FMPayBankAccountReconciliationModel>;
  workSchedulingOperation: FMPayPagination<FMPayBankAccountReconciliationModel>;
  previous: FMPayPagination<FMPayBankAccountReconciliationModel>;
  users: FMPayWorkSchedulingOperationModel[];
  total: {
    expected: number;
    workSchedulingBalance: number;
  };
  percentage: FMPayWorkSchedulingPercentageModel[];
  loading: {
    findById: boolean;
    deleted: string[];
    lock: string;
    lockWorkScheduling: string;
    user: boolean;
    pagination: boolean;
    workScheduling: boolean;
    previous: boolean;
    update: string;
    create: boolean;
    check: boolean;
  };
}

const InitialState: BankAccountReconciliationState = {
  model: null,
  models: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  workSchedulingOperation: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  previous: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  users: [],
  total: { expected: 0, workSchedulingBalance: 0 },
  percentage: [],
  loading: {
    findById: false,
    deleted: [],
    lock: "",
    lockWorkScheduling: "",
    user: false,
    pagination: false,
    workScheduling: false,
    previous: false,
    update: "",
    create: false,
    check: false
  }
};

export const bankAccountReconciliationSlice = createSlice({
  name: "bankAccountReconciliation",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(bankAccountReconciliationFindById.pending, (state) => {
      state.loading.findById = true;
    });
    builder.addCase(bankAccountReconciliationFindById.rejected, (state) => {
      state.loading.findById = false;
    });
    builder.addCase(bankAccountReconciliationFindById.fulfilled, (state, action) => {
      state.loading.findById = false;
      state.model = action.payload;
    });

    builder.addCase(bankAccountReconciliationDeleteByIds.pending, (state, action) => {
      state.loading.deleted = action.meta.arg;
    });
    builder.addCase(bankAccountReconciliationDeleteByIds.rejected, (state) => {
      state.loading.deleted = [];
    });
    builder.addCase(bankAccountReconciliationDeleteByIds.fulfilled, (state) => {
      state.loading.deleted = [];
    });

    builder.addCase(bankAccountReconciliationLockById.pending, (state, action) => {
      state.loading.lock = action.meta.arg;
    });
    builder.addCase(bankAccountReconciliationLockById.rejected, (state) => {
      state.loading.lock = "";
    });
    builder.addCase(bankAccountReconciliationLockById.fulfilled, (state) => {
      state.loading.lock = "";
    });

    builder.addCase(bankAccountReconciliationCheckedByWorkScheduling.pending, (state) => {
      state.loading.check = true;
    });
    builder.addCase(bankAccountReconciliationCheckedByWorkScheduling.rejected, (state) => {
      state.loading.check = false;
    });
    builder.addCase(bankAccountReconciliationCheckedByWorkScheduling.fulfilled, (state) => {
      state.loading.check = false;
    });

    builder.addCase(
      bankAccountReconciliationLockWorkSchedulingOperationById.pending,
      (state, action) => {
        state.loading.lockWorkScheduling = action.meta.arg;
      }
    );
    builder.addCase(bankAccountReconciliationLockWorkSchedulingOperationById.rejected, (state) => {
      state.loading.lockWorkScheduling = "";
    });
    builder.addCase(bankAccountReconciliationLockWorkSchedulingOperationById.fulfilled, (state) => {
      state.loading.lockWorkScheduling = "";
    });

    builder.addCase(bankAccountReconciliationQueryUsers.pending, (state) => {
      state.loading.user = true;
    });
    builder.addCase(bankAccountReconciliationQueryUsers.rejected, (state) => {
      state.loading.user = false;
    });
    builder.addCase(bankAccountReconciliationQueryUsers.fulfilled, (state, action) => {
      state.loading.user = false;
      state.users = action.payload;
    });

    builder.addCase(bankAccountReconciliationUpdate.pending, (state, action) => {
      state.loading.update = action.meta.arg.id;
    });
    builder.addCase(bankAccountReconciliationUpdate.rejected, (state) => {
      state.loading.update = "";
    });
    builder.addCase(bankAccountReconciliationUpdate.fulfilled, (state, action) => {
      state.loading.update = "";
      state.model = action.payload;
    });

    builder.addCase(bankAccountReconciliationPagination.pending, (state) => {
      state.loading.pagination = true;
    });
    builder.addCase(bankAccountReconciliationPagination.rejected, (state) => {
      state.loading.pagination = false;
    });
    builder.addCase(bankAccountReconciliationPagination.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.models = action.payload;
    });

    builder.addCase(bankAccountReconciliationPaginationByWSO.pending, (state) => {
      state.loading.workScheduling = true;
    });
    builder.addCase(bankAccountReconciliationPaginationByWSO.rejected, (state) => {
      state.loading.workScheduling = false;
    });
    builder.addCase(bankAccountReconciliationPaginationByWSO.fulfilled, (state, action) => {
      state.loading.workScheduling = false;
      state.workSchedulingOperation = action.payload;

      const expected = action.payload.data.reduce<Decimal>((a, b) => {
        return a.plus(new Decimal(b.expectedBalance));
      }, new Decimal(0));
      const workSchedulingBalance = action.payload.data.reduce<Decimal>((a, b) => {
        return a.plus(new Decimal(b.workSchedulingBalance));
      }, new Decimal(0));

      state.total.expected = expected.div(100).toNumber();
      state.total.workSchedulingBalance = workSchedulingBalance.div(100).toNumber();
    });

    builder.addCase(bankAccountReconciliationPrevious.pending, (state) => {
      state.loading.previous = true;
    });
    builder.addCase(bankAccountReconciliationPrevious.rejected, (state) => {
      state.loading.previous = false;
    });
    builder.addCase(bankAccountReconciliationPrevious.fulfilled, (state, action) => {
      state.loading.previous = false;
      state.previous = action.payload;
    });

    builder.addCase(bankAccountReconciliationCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(bankAccountReconciliationCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(bankAccountReconciliationCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });
  }
});

export default bankAccountReconciliationSlice.reducer;
