import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Services } from "fp/store/Services";
import { FMPayPaginationQuery, FMPayResponse, FMPayResponsePagination, SendSMSModel } from "fp/store/type";
import { SendSMSDTO } from "fp/store/dto/sms/SendSMSDTO";
import {
  BulkSMSBalance,
  BulkSMSMessageReportQuery,
  BulkSMSMessageReportResponse,
} from "fp/components/sms/BulkSMSStatus";

export const bulkSMSSend = createAsyncThunk<SendSMSModel, SendSMSDTO>("bulk.sms.send", async (p) => {
  const resp = await Services.FMPaySMS.send(p);
  return resp.data;
});

export const bulkSMSResend = createAsyncThunk<SendSMSModel, string>("bulk.sms.resend", async (id) => {
  const resp = await Services.FMPaySMS.resend(id);
  return resp.data;
});

export const bulkSMSRecord = createAsyncThunk<FMPayResponsePagination<SendSMSModel>, FMPayPaginationQuery>(
  "bulk.sms.record",
  async (p) => {
    const resp = await Services.FMPaySMS.pagination(p);
    return resp;
  }
);

export const bulkSMSCheckBalance = createAsyncThunk<FMPayResponse<BulkSMSBalance>, void>(
  "bulk.sms.check.balance",
  async () => {
    return await Services.FMPaySMS.balance();
  }
);

export const bulkSMSMessageReport = createAsyncThunk<
  FMPayResponse<BulkSMSMessageReportResponse>,
  BulkSMSMessageReportQuery
>("bulk.sms.message.report", async (p) => {
  return await Services.FMPaySMS.messageReport(p);
});

export interface BulkSMSState {
  model: SendSMSModel | null;
  record: FMPayResponsePagination<SendSMSModel>;
  balance: BulkSMSBalance;
  reports: BulkSMSMessageReportResponse;
  loading: {
    send: boolean;
    resend: boolean;
    record: boolean;
    balance: boolean;
    reports: boolean;
  };
}

const InitialState: BulkSMSState = {
  model: null,
  record: {
    message: "",
    status: "",
    success: true,
    data: {
      current: 1,
      size: 10,
      total: 0,
      pages: 0,
      latest: false,
      data: [],
    },
  },
  balance: {
    amount: 0.0,
    status: 1,
  },
  reports: {
    status: 1,
    data: [],
    total: 0,
    page: 1,
    rows: 0,
  },
  loading: {
    send: false,
    resend: false,
    record: false,
    balance: false,
    reports: false,
  },
};

export const bulkSMSSlice = createSlice({
  name: "bulkSMS",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(bulkSMSSend.pending, (state) => {
      state.loading.send = true;
    });

    builder.addCase(bulkSMSSend.rejected, (state) => {
      state.loading.send = false;
    });

    builder.addCase(bulkSMSSend.fulfilled, (state, action) => {
      state.loading.send = false;
      state.model = action.payload;
    });

    builder.addCase(bulkSMSResend.pending, (state) => {
      state.loading.resend = true;
    });
    builder.addCase(bulkSMSResend.rejected, (state) => {
      state.loading.resend = false;
    });
    builder.addCase(bulkSMSResend.fulfilled, (state, action) => {
      state.loading.resend = false;
      state.model = action.payload;
    });

    builder.addCase(bulkSMSRecord.pending, (state) => {
      state.loading.record = true;
    });
    builder.addCase(bulkSMSRecord.rejected, (state) => {
      state.loading.record = false;
    });
    builder.addCase(bulkSMSRecord.fulfilled, (state, action) => {
      state.loading.record = false;
      state.record = action.payload;
    });

    builder.addCase(bulkSMSCheckBalance.pending, (state) => {
      state.loading.balance = true;
    });
    builder.addCase(bulkSMSCheckBalance.rejected, (state) => {
      state.loading.balance = false;
    });
    builder.addCase(bulkSMSCheckBalance.fulfilled, (state, action) => {
      state.loading.balance = false;
      state.balance = action.payload.data;
    });

    builder.addCase(bulkSMSMessageReport.pending, (state) => {
      state.loading.reports = true;
    });
    builder.addCase(bulkSMSMessageReport.rejected, (state) => {
      state.loading.reports = false;
    });
    builder.addCase(bulkSMSMessageReport.fulfilled, (state, action) => {
      state.loading.reports = false;
      state.reports = action.payload.data;
    });
  },
});

export default bulkSMSSlice.reducer;
