import { FMPayPaginationQuery, FMPayResponse, FMPayResponsePagination } from "fp/store/type";
import { CreateDTO, FMPayMediaAccountModel, PaginationQuery } from "fp/modules/media-account/type";
import { HttpClient } from "fp/store/HttpClient";

export class Service {
  public static async findById(id: string): Promise<FMPayResponse<FMPayMediaAccountModel>> {
    return await HttpClient.Get(`/v1/media/account/${id}`);
  }

  public static async deleteByIds(ids: string[]): Promise<FMPayResponse<boolean>> {
    return await HttpClient.Post(`/v1/media/account/deleted`, { ids });
  }

  public static async pagination(
    query: FMPayPaginationQuery<Partial<PaginationQuery>>
  ): Promise<FMPayResponsePagination<FMPayMediaAccountModel>> {
    return await HttpClient.Post(`/v1/media/account/pagination`, query);
  }
  public static async update(
    model: FMPayMediaAccountModel
  ): Promise<FMPayResponse<FMPayMediaAccountModel>> {
    return await HttpClient.Post(`/v1/media/account/update`, model);
  }

  public static async create(dto: CreateDTO): Promise<FMPayResponse<FMPayMediaAccountModel>> {
    return await HttpClient.Post(`/v1/media/account/add`, dto);
  }
}
