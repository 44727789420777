import { FMPayPaginationQuery, FMPayResponse, FMPayResponsePagination } from "fp/store/type";
import {
  CreateDTO,
  FMPayWorkSchedulingModel,
  PaginationQuery
} from "fp/modules/work-scheduling/type";
import { HttpClient } from "fp/store/HttpClient";

export class Service {
  public static async findById(id: string): Promise<FMPayResponse<FMPayWorkSchedulingModel>> {
    return await HttpClient.Get(`/v1/work/scheduling/${id}`);
  }

  public static async deleteByIds(ids: string[]): Promise<FMPayResponse<boolean>> {
    return await HttpClient.Post(`/v1/work/scheduling/deleted`, { ids });
  }

  public static async pagination(
    query: FMPayPaginationQuery<Partial<PaginationQuery>>
  ): Promise<FMPayResponsePagination<FMPayWorkSchedulingModel>> {
    return await HttpClient.Post(`/v1/work/scheduling/pagination`, query);
  }

  public static async today(
    query: FMPayPaginationQuery<{}>
  ): Promise<FMPayResponsePagination<FMPayWorkSchedulingModel>> {
    return await HttpClient.Post(`/v1/work/scheduling/today`, query);
  }

  public static async update(
    model: FMPayWorkSchedulingModel
  ): Promise<FMPayResponse<FMPayWorkSchedulingModel>> {
    return await HttpClient.Post(`/v1/work/scheduling/update`, model);
  }

  public static async create(dto: CreateDTO): Promise<FMPayResponse<FMPayWorkSchedulingModel>> {
    return await HttpClient.Post(`/v1/work/scheduling/add`, dto);
  }
}
