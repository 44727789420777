import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Service } from "fp/modules/order/Service";
import {
  CreateDTO,
  FMPayOrderModel,
  PaginationQuery,
  SumTotalByUserWorkSchedulingResultDTO,
  UpdateByStaffDTO,
  UpdateDTO
} from "fp/modules/order/type";
import { FMPayPagination, FMPayPaginationQuery, FMPayResponse } from "fp/store/type";

export const orderFindById = createAsyncThunk<FMPayOrderModel, string>(
  "order/find.by.id",
  async (id: string) => {
    const resp = await Service.findById(id);
    return resp.data;
  }
);

export const orderLatestById = createAsyncThunk<FMPayOrderModel, string>(
  "order/latest.by.id",
  async (id: string) => {
    const resp = await Service.findById(id);
    return resp.data;
  }
);

export const orderPagination = createAsyncThunk<
  FMPayPagination<FMPayOrderModel>,
  FMPayPaginationQuery<Partial<PaginationQuery>>
>("order/pagination", async (query: FMPayPaginationQuery<Partial<PaginationQuery>>) => {
  const resp = await Service.pagination(query);
  return resp.data;
});

export const orderUpdate = createAsyncThunk<FMPayOrderModel, UpdateDTO>(
  "order/update",
  async (model: UpdateDTO) => {
    const resp = await Service.update(model);
    return resp.data;
  }
);

export const orderUpdateByStaff = createAsyncThunk<FMPayOrderModel, UpdateByStaffDTO>(
  "order/update.by.staff",
  async (model: UpdateByStaffDTO) => {
    const resp = await Service.updateByStaff(model);
    return resp.data;
  }
);

export const orderCreate = createAsyncThunk<FMPayOrderModel, CreateDTO>(
  "order/create",
  async (dto: CreateDTO) => {
    const resp = await Service.create(dto);
    return resp.data;
  }
);

export const orderSumByWorkSchedulingOperation = createAsyncThunk<
  SumTotalByUserWorkSchedulingResultDTO[],
  {
    workSchedulingOperationId: string;
  }
>("order/sum.by.work.scheduling.operation", async (dto) => {
  const resp = await Service.sumByWorkSchedulingOperation(dto);
  return resp.data;
});

export const orderNullity = createAsyncThunk<FMPayOrderModel, string>(
  "order/nullify",
  async (id: string) => {
    const resp = await Service.nullify({ id });
    return resp.data;
  }
);

export const orderResetStatus = createAsyncThunk<FMPayOrderModel, string>(
  "order/reset.status",
  async (id: string) => {
    const resp = await Service.resetStatus({ id });
    return resp.data;
  }
);

export interface OrderState {
  model: FMPayOrderModel | null;
  models: FMPayPagination<FMPayOrderModel>;
  sum: SumTotalByUserWorkSchedulingResultDTO[];
  loading: {
    sum: boolean;
    findById: boolean;
    nullifyId: string;
    resetStatusId: string;
    pagination: boolean;
    update: string;
    latest: string;
    updateByStaff: string;
    create: boolean;
  };
}

const InitialState: OrderState = {
  model: null,
  models: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  sum: [],
  loading: {
    sum: false,
    findById: false,
    nullifyId: "",
    resetStatusId: "",
    pagination: false,
    update: "",
    latest: "",
    updateByStaff: "",
    create: false
  }
};

export const orderSlice = createSlice({
  name: "order",
  initialState: { ...InitialState },
  reducers: {
    orderReset(state) {
      state.model = InitialState.model;
      state.models = InitialState.models;
      state.loading = InitialState.loading;
    }
  },
  extraReducers(builder) {
    builder.addCase(orderFindById.pending, (state) => {
      state.loading.findById = true;
    });
    builder.addCase(orderFindById.rejected, (state) => {
      state.loading.findById = false;
    });
    builder.addCase(orderFindById.fulfilled, (state, action) => {
      state.loading.findById = false;
      state.model = action.payload;
    });

    builder.addCase(orderUpdate.pending, (state, action) => {
      state.loading.update = action.meta.arg.id;
    });
    builder.addCase(orderUpdate.rejected, (state) => {
      state.loading.update = "";
    });
    builder.addCase(orderUpdate.fulfilled, (state, action) => {
      state.loading.update = "";
      state.model = action.payload;
    });

    builder.addCase(orderSumByWorkSchedulingOperation.pending, (state) => {
      state.loading.sum = true;
    });
    builder.addCase(orderSumByWorkSchedulingOperation.rejected, (state) => {
      state.loading.sum = false;
    });
    builder.addCase(orderSumByWorkSchedulingOperation.fulfilled, (state, action) => {
      state.loading.sum = false;
      state.sum = action.payload;
    });

    builder.addCase(orderUpdateByStaff.pending, (state, action) => {
      state.loading.updateByStaff = action.meta.arg.id;
    });
    builder.addCase(orderUpdateByStaff.rejected, (state) => {
      state.loading.updateByStaff = "";
    });
    builder.addCase(orderUpdateByStaff.fulfilled, (state, action) => {
      state.loading.updateByStaff = "";
      state.model = action.payload;
    });

    builder.addCase(orderPagination.pending, (state) => {
      state.loading.pagination = true;
    });
    builder.addCase(orderPagination.rejected, (state) => {
      state.loading.pagination = false;
    });
    builder.addCase(orderPagination.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.models = action.payload;
    });

    builder.addCase(orderCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(orderCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(orderCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });

    builder.addCase(orderLatestById.pending, (state, action) => {
      state.loading.latest = action.meta.arg;
    });
    builder.addCase(orderLatestById.rejected, (state) => {
      state.loading.latest = "";
    });
    builder.addCase(orderLatestById.fulfilled, (state, action) => {
      state.loading.latest = "";
      const order = action.payload;

      if (order === null) {
        return;
      }

      state.models.data = state.models.data.map((r) => {
        if (r.id === order.id) {
          return order;
        }
        return r;
      });
    });

    builder.addCase(orderNullity.pending, (state, action) => {
      state.loading.nullifyId = action.meta.arg;
    });
    builder.addCase(orderNullity.rejected, (state) => {
      state.loading.nullifyId = "";
    });
    builder.addCase(orderNullity.fulfilled, (state) => {
      state.loading.nullifyId = "";
    });

    builder.addCase(orderResetStatus.pending, (state, action) => {
      state.loading.resetStatusId = action.meta.arg;
    });
    builder.addCase(orderResetStatus.rejected, (state) => {
      state.loading.resetStatusId = "";
    });
    builder.addCase(orderResetStatus.fulfilled, (state) => {
      state.loading.resetStatusId = "";
    });
  }
});

export default orderSlice.reducer;

export const { orderReset } = orderSlice.actions;
