import { createBrowserRouter, RouteObject } from "react-router-dom";
import { Bootstrap } from "fp/routes/Bootstrap";
import { Login } from "fp/components/user/Login";
import { Exception404 } from "fp/modules/exception/404";
import React, { lazy, ReactNode } from "react";
import { UserRole } from "fp/store/type";
import { Authorization } from "fp/components/Authorization";
import { RsLayout } from "fp/components/RsLayout";
import {
  CodepenOutlined,
  DashboardOutlined,
  UsergroupAddOutlined,
  ApartmentOutlined,
  MessageOutlined,
  OrderedListOutlined,
  FileAddOutlined,
  BookOutlined,
  PayCircleOutlined,
  TeamOutlined,
  SolutionOutlined,
  FieldTimeOutlined,
  StrikethroughOutlined,
  AccountBookOutlined
} from "@ant-design/icons";
import { Spin } from "antd";

export interface FMPayRouter {
  // menu
  label?: ReactNode;
  key?: string;
  icon?: ReactNode;
  showMenu?: boolean;
  role?: UserRole;
  // router
  layout?: React.FC<{ children: ReactNode }>;
  element?: ReactNode;
  path?: string;
  lazy?: React.LazyExoticComponent<React.FunctionComponent<{}>>;
  children?: FMPayRouter[];
}

export const Routes: FMPayRouter[] = [
  {
    path: "/",
    element: <Bootstrap redirect="/dashboard" />
  },
  {
    path: "/login",
    element: <Login redirect="/dashboard" />
  },
  // 工作区
  {
    label: "工作区",
    icon: <DashboardOutlined />,
    showMenu: true,
    layout: RsLayout,
    path: "/dashboard",
    role: UserRole.STAFF,
    lazy: lazy(() => import("fp/routes/Dashboard"))
  },
  // 管理员
  {
    label: "管理员",
    key: "admin",
    icon: <ApartmentOutlined />,
    showMenu: true,
    children: [
      {
        label: "安全码",
        key: "security.code",
        icon: <CodepenOutlined />,
        showMenu: true,
        layout: RsLayout,
        path: "/security/code",
        role: UserRole.MANAGER,
        lazy: lazy(() => import("fp/components/security/SecurityCode"))
      },
      {
        label: "公司管理",
        key: "company",
        icon: <ApartmentOutlined />,
        showMenu: true,
        layout: RsLayout,
        path: "/company",
        role: UserRole.ADMIN,
        lazy: lazy(() => import("fp/components/company/Company"))
      },
      {
        label: "用户管理",
        key: "user",
        icon: <TeamOutlined />,
        showMenu: true,
        layout: RsLayout,
        path: "/user",
        role: UserRole.MANAGER,
        lazy: lazy(() => import("fp/components/user/User"))
      },
      {
        label: "运营账户",
        key: "media.account",
        icon: <SolutionOutlined />,
        showMenu: true,
        layout: RsLayout,
        path: "/media/account",
        role: UserRole.MANAGER,
        lazy: lazy(() => import("fp/modules/media-account/MediaAccount"))
      },
      {
        label: "排班",
        key: "work.time.planning",
        icon: <FieldTimeOutlined />,
        showMenu: true,
        layout: RsLayout,
        path: "/work/time/planning",
        role: UserRole.MANAGER,
        lazy: lazy(() => import("fp/modules/work-time-planning/WorkTimePlanning"))
      },
      {
        label: "分配班次",
        key: "work.scheduling.operation",
        icon: <FieldTimeOutlined />,
        showMenu: true,
        layout: RsLayout,
        path: "/work/scheduling/operation",
        role: UserRole.MANAGER,
        lazy: lazy(() => import("fp/modules/work-scheduling-operation/WorkSchedulingOperation"))
      }
    ]
  },
  // 群组
  {
    label: "群组",
    key: "group",
    icon: <UsergroupAddOutlined />,
    showMenu: true,
    children: [
      {
        label: "群组管理",
        key: "group.manage",
        icon: <UsergroupAddOutlined />,
        showMenu: true,
        layout: RsLayout,
        role: UserRole.STAFF,
        path: "/group/manage",
        lazy: lazy(() => import("fp/components/account/Group"))
      },
      {
        showMenu: false,
        path: "/group/create",
        layout: RsLayout,
        lazy: lazy(() => import("fp/components/account/CreateGroup"))
      }
      // {
      //   label: "群组账本",
      //   key: "group.account.book",
      //   icon: <AccountBookOutlined />,
      //   showMenu: true,
      //   layout: RsLayout,
      //   role: UserRole.MANAGER,
      //   path: "/group/account/book",
      //   lazy: lazy(() => import("fp/components/account/GroupAccountBook"))
      // }
    ]
  },

  // 账本
  {
    label: "帐本",
    icon: <AccountBookOutlined />,
    showMenu: true,
    children: [
      {
        label: "银行对账",
        key: "payment.bank.account.reconciliation",
        icon: <PayCircleOutlined />,
        showMenu: true,
        layout: RsLayout,
        role: UserRole.STAFF,
        path: "/payment/bank/account/reconciliation",
        lazy: lazy(() => import("fp/modules/bank-account-reconciliation/BankAccountReconciliation"))
      },
      {
        label: "奈拉打错误记录",
        key: "transfer.error.record",
        icon: <StrikethroughOutlined />,
        showMenu: true,
        layout: RsLayout,
        role: UserRole.STAFF,
        path: "/transfer/error/record",
        lazy: lazy(() => import("fp/modules/transfer-error-record/TransferErrorRecord"))
      },
      {
        label: "运营数据",
        key: "operation.record",
        icon: <StrikethroughOutlined />,
        showMenu: true,
        layout: RsLayout,
        role: UserRole.STAFF,
        path: "/operation/record",
        lazy: lazy(() => import("fp/modules/operation-record/OperationRecord"))
      }
    ]
  },

  // 支付管理
  {
    label: "支付管理",
    icon: <PayCircleOutlined />,
    showMenu: true,
    children: [
      {
        label: "银行管理",
        key: "payment.bank",
        icon: <PayCircleOutlined />,
        showMenu: true,
        layout: RsLayout,
        role: UserRole.STAFF,
        path: "/payment/bank",
        lazy: lazy(() => import("fp/modules/normal-bank/NormalBank"))
      },
      {
        label: "充值记录",
        key: "payment.recharge.record",
        icon: <PayCircleOutlined />,
        showMenu: true,
        layout: RsLayout,
        role: UserRole.STAFF,
        path: "/payment/recharge/record",
        lazy: lazy(() => import("fp/modules/recharge-record/RechargeRecord"))
      },
      {
        label: "Moniepoint支付",
        key: "payment.moniepoint",
        icon: <PayCircleOutlined />,
        showMenu: true,
        layout: RsLayout,
        role: UserRole.STAFF,
        path: "/payment/moniepoint",
        lazy: lazy(() => import("fp/components/moniepoint/MoniepointTask"))
      },
      {
        label: "NGN支付",
        key: "payment.ngnpay",
        icon: <PayCircleOutlined />,
        showMenu: true,
        layout: RsLayout,
        role: UserRole.STAFF,
        path: "/payment/ngn",
        lazy: lazy(() => import("fp/modules/ngnpay-account/NGNPayAccount"))
      },
      {
        label: "NGN通知",
        key: "payment.ngnpay.notify",
        icon: <PayCircleOutlined />,
        showMenu: true,
        layout: RsLayout,
        role: UserRole.STAFF,
        path: "/payment/ngn/notify",
        lazy: lazy(() => import("fp/modules/ngnpay/NGNPay"))
      }
    ]
  },

  // 短信服务
  {
    label: "SMS（短信服务）",
    key: "sms",
    icon: <MessageOutlined />,
    showMenu: true,
    children: [
      {
        label: "发送短信",
        key: "sms.new",
        icon: <FileAddOutlined />,
        showMenu: true,
        layout: RsLayout,
        role: UserRole.MANAGER,
        path: "/sms/new",
        lazy: lazy(() => import("fp/components/sms/SendSMS"))
      },
      {
        label: "核销码",
        key: "sms.code.consume",
        icon: <FileAddOutlined />,
        showMenu: true,
        layout: RsLayout,
        role: UserRole.MANAGER,
        path: "/sms/code/consume",
        lazy: lazy(() => import("fp/components/sms/SMSConsumeCodeRecord"))
      },
      {
        label: "发送记录",
        key: "sms.record",
        icon: <OrderedListOutlined />,
        showMenu: true,
        layout: RsLayout,
        path: "/sms/record",
        lazy: lazy(() => import("fp/components/sms/SendSMSRecord"))
      },
      {
        label: "发送报告",
        key: "sms.message.report",
        icon: <BookOutlined />,
        showMenu: true,
        layout: RsLayout,
        path: "/sms/message/report/:recordId",
        lazy: lazy(() => import("fp/components/sms/BulkSMSMessageReport"))
      }
    ]
  },

  // 404
  {
    path: "*",
    element: <Exception404 />
  }
];

function getReactRouter(routes: FMPayRouter[]): RouteObject[] {
  const result: RouteObject[] = [];

  for (const item of routes) {
    const router: RouteObject = {};

    if (item.element !== void 0) {
      router.element = item.element;
    }

    if (item.lazy !== void 0) {
      router.element = (
        <React.Suspense fallback={<Spin spinning />}>
          <item.lazy />
        </React.Suspense>
      );
    }

    if (item.role !== void 0) {
      router.element = <Authorization role={item.role}>{router.element}</Authorization>;
    }

    if (item.layout !== void 0) {
      router.element = <item.layout>{router.element}</item.layout>;
    }

    if (item.path) {
      router.path = item.path;
      result.push(router);
    }

    if (item.children) {
      const children = getReactRouter(item.children);
      for (const child of children) {
        result.push(child);
      }
    }
  }

  return result;
}

const reactRouterRoutes: RouteObject[] = getReactRouter(Routes);
export const router = createBrowserRouter(reactRouterRoutes);
