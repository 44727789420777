import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Service } from "fp/modules/work-time-planning/Service";
import {
  CreateDTO,
  FMPayWorkTimePlanningModel,
  PaginationQuery
} from "fp/modules/work-time-planning/type";
import { FMPayPagination, FMPayPaginationQuery } from "fp/store/type";

export const workTimePlanningFindById = createAsyncThunk<FMPayWorkTimePlanningModel, string>(
  "work.time.planning/find.by.id",
  async (id: string) => {
    const resp = await Service.findById(id);
    return resp.data;
  }
);

export const workTimePlanningDeleteByIds = createAsyncThunk<boolean, string[]>(
  "work.time.planning/delete.by.ids",
  async (ids: string[]) => {
    const resp = await Service.deleteByIds(ids);
    return resp.data;
  }
);

export const workTimePlanningPagination = createAsyncThunk<
  FMPayPagination<FMPayWorkTimePlanningModel>,
  FMPayPaginationQuery<Partial<PaginationQuery>>
>(
  "work.time.planning/pagination",
  async (query: FMPayPaginationQuery<Partial<PaginationQuery>>) => {
    const resp = await Service.pagination(query);
    return resp.data;
  }
);

export const workTimePlanningTodayPagination = createAsyncThunk<FMPayWorkTimePlanningModel[], void>(
  "work.time.planning/today.pagination",
  async () => {
    const resp = await Service.today();
    return resp.data;
  }
);

export const workTimePlanningUpdate = createAsyncThunk<
  FMPayWorkTimePlanningModel,
  FMPayWorkTimePlanningModel
>("work.time.planning/update", async (model: FMPayWorkTimePlanningModel) => {
  const resp = await Service.update(model);
  return resp.data;
});

export const workTimePlanningCreate = createAsyncThunk<FMPayWorkTimePlanningModel, CreateDTO>(
  "work.time.planning/create",
  async (dto: CreateDTO) => {
    const resp = await Service.create(dto);
    return resp.data;
  }
);

export interface WorkTimePlanningState {
  model: FMPayWorkTimePlanningModel | null;
  models: FMPayPagination<FMPayWorkTimePlanningModel>;
  today: FMPayWorkTimePlanningModel[];
  loading: {
    findById: boolean;
    deleted: string[];
    pagination: boolean;
    today: boolean;
    update: string;
    create: boolean;
  };
}

const InitialState: WorkTimePlanningState = {
  model: null,
  models: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  today: [],
  loading: {
    findById: false,
    deleted: [],
    pagination: false,
    today: false,
    update: "",
    create: false
  }
};

export const workTimePlanningSlice = createSlice({
  name: "workTimePlanning",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(workTimePlanningFindById.pending, (state) => {
      state.loading.findById = true;
    });
    builder.addCase(workTimePlanningFindById.rejected, (state) => {
      state.loading.findById = false;
    });
    builder.addCase(workTimePlanningFindById.fulfilled, (state, action) => {
      state.loading.findById = false;
      state.model = action.payload;
    });

    builder.addCase(workTimePlanningDeleteByIds.pending, (state, action) => {
      state.loading.deleted = action.meta.arg;
    });
    builder.addCase(workTimePlanningDeleteByIds.rejected, (state) => {
      state.loading.deleted = [];
    });
    builder.addCase(workTimePlanningDeleteByIds.fulfilled, (state) => {
      state.loading.deleted = [];
    });

    builder.addCase(workTimePlanningUpdate.pending, (state, action) => {
      state.loading.update = action.meta.arg.id;
    });
    builder.addCase(workTimePlanningUpdate.rejected, (state) => {
      state.loading.update = "";
    });
    builder.addCase(workTimePlanningUpdate.fulfilled, (state, action) => {
      state.loading.update = "";
      state.model = action.payload;
    });

    builder.addCase(workTimePlanningPagination.pending, (state) => {
      state.loading.pagination = true;
    });
    builder.addCase(workTimePlanningPagination.rejected, (state) => {
      state.loading.pagination = false;
    });
    builder.addCase(workTimePlanningPagination.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.models = action.payload;
    });

    builder.addCase(workTimePlanningTodayPagination.pending, (state) => {
      state.loading.today = true;
    });
    builder.addCase(workTimePlanningTodayPagination.rejected, (state) => {
      state.loading.today = false;
    });
    builder.addCase(workTimePlanningTodayPagination.fulfilled, (state, action) => {
      state.loading.today = false;
      state.today = action.payload;
    });

    builder.addCase(workTimePlanningCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(workTimePlanningCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(workTimePlanningCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });
  }
});

export default workTimePlanningSlice.reducer;
