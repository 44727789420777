import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import Decimal from "decimal.js";
import { Service } from "fp/modules/work-scheduling-percentage/Service";
import {
  FMPayWorkSchedulingPercentageModel,
  PaginationQuery
} from "fp/modules/work-scheduling-percentage/type";
import { FMPayPagination, FMPayPaginationQuery, FMPayResponse } from "fp/store/type";
import { workSchedulingDeleteByIds } from "../work-scheduling/slice";

export const workSchedulingPercentageFindById = createAsyncThunk<
  FMPayWorkSchedulingPercentageModel,
  string
>("work.scheduling.percentage/find.by.id", async (id: string) => {
  const resp = await Service.findById(id);
  return resp.data;
});

export const workSchedulingPercentageDeleteByIds = createAsyncThunk<boolean, string[]>(
  "work.scheduling.percentage/delete.by.ids",
  async (ids: string[]) => {
    const resp = await Service.deleteByIds(ids);
    return resp.data;
  }
);

export const workSchedulingPercentagePagination = createAsyncThunk<
  FMPayPagination<FMPayWorkSchedulingPercentageModel>,
  FMPayPaginationQuery<Partial<PaginationQuery>>
>(
  "work.scheduling.percentage/pagination",
  async (query: FMPayPaginationQuery<Partial<PaginationQuery>>) => {
    const resp = await Service.pagination(query);
    return resp.data;
  }
);

export const workSchedulingPercentageMonth = createAsyncThunk<
  FMPayPagination<FMPayWorkSchedulingPercentageModel>
>("work.scheduling.percentage/month", async () => {
  const resp = await Service.pagination({
    current: 1,
    size: 999,
    query: {
      time: [dayjs().startOf("month").valueOf(), dayjs().endOf("month").valueOf()]
    }
  });
  return resp.data;
});

export const workSchedulingPercentageToday = createAsyncThunk<
  FMPayPagination<FMPayWorkSchedulingPercentageModel>
>("work.scheduling.percentage/today", async () => {
  const resp = await Service.pagination({
    current: 1,
    size: 999,
    query: {
      time: [dayjs().startOf("day").valueOf(), dayjs().endOf("day").valueOf()]
    }
  });
  return resp.data;
});

export const workSchedulingPercentageCurrentWorkScheduling =
  createAsyncThunk<FMPayWorkSchedulingPercentageModel | null>(
    "work.scheduling.percentage/current.work.scheduling",
    async () => {
      const resp = await Service.currentWorkScheduling();
      return resp.success ? resp.data : null;
    }
  );

export const workSchedulingPercentageUpdate = createAsyncThunk<
  FMPayWorkSchedulingPercentageModel,
  FMPayWorkSchedulingPercentageModel
>("work.scheduling.percentage/update", async (model: FMPayWorkSchedulingPercentageModel) => {
  const resp = await Service.update(model);
  return resp.data;
});

export interface WorkSchedulingPercentageState {
  month: FMPayWorkSchedulingPercentageModel[];
  today: FMPayWorkSchedulingPercentageModel[];
  models: FMPayPagination<FMPayWorkSchedulingPercentageModel>;
  currentWorkScheduling: FMPayWorkSchedulingPercentageModel | null;
  total: {
    month: number;
    today: number;
    currentWorkScheduling: number;
  };
  loading: {
    deleted: string[];
    month: boolean;
    today: boolean;
    pagination: boolean;
    currentWorkScheduling: boolean;
  };
}

const InitialState: WorkSchedulingPercentageState = {
  month: [],
  today: [],
  models: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },

  currentWorkScheduling: null,
  total: {
    month: 0,
    today: 0,
    currentWorkScheduling: 0
  },
  loading: {
    deleted: [],
    month: false,
    today: false,
    pagination: false,
    currentWorkScheduling: false
  }
};

export const workSchedulingPercentageSlice = createSlice({
  name: "workSchedulingPercentage",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(workSchedulingPercentageMonth.pending, (state) => {
      state.loading.month = true;
    });
    builder.addCase(workSchedulingPercentageMonth.rejected, (state) => {
      state.loading.month = false;
    });
    builder.addCase(workSchedulingPercentageMonth.fulfilled, (state, action) => {
      state.loading.month = false;
      state.month = action.payload.data;
      const total = action.payload.data.reduce<Decimal>((a, b) => {
        return a.plus(new Decimal(b.value));
      }, new Decimal(0));
      state.total.month = total.div(100).toNumber();
    });

    builder.addCase(workSchedulingPercentageToday.pending, (state) => {
      state.loading.today = true;
    });
    builder.addCase(workSchedulingPercentageToday.rejected, (state) => {
      state.loading.today = false;
    });
    builder.addCase(workSchedulingPercentageToday.fulfilled, (state, action) => {
      state.loading.today = false;
      state.today = action.payload.data;
      const total = action.payload.data.reduce<Decimal>((a, b) => {
        return a.plus(new Decimal(b.value));
      }, new Decimal(0));
      state.total.today = total.div(100).toNumber();
    });

    builder.addCase(workSchedulingPercentageCurrentWorkScheduling.pending, (state) => {
      state.loading.currentWorkScheduling = true;
    });
    builder.addCase(workSchedulingPercentageCurrentWorkScheduling.rejected, (state) => {
      state.loading.currentWorkScheduling = false;
    });
    builder.addCase(workSchedulingPercentageCurrentWorkScheduling.fulfilled, (state, action) => {
      state.loading.currentWorkScheduling = false;
      state.currentWorkScheduling = action.payload;
      if (state.currentWorkScheduling !== null) {
        state.total.currentWorkScheduling = new Decimal(state.currentWorkScheduling.value)
          .div(100)
          .toNumber();
      } else {
        state.total.currentWorkScheduling = 0;
      }
    });

    builder.addCase(workSchedulingPercentagePagination.pending, (state) => {
      state.loading.pagination = true;
    });
    builder.addCase(workSchedulingPercentagePagination.rejected, (state) => {
      state.loading.pagination = false;
    });
    builder.addCase(workSchedulingPercentagePagination.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.models = action.payload;
    });

    builder.addCase(workSchedulingDeleteByIds.pending, (state, action) => {
      state.loading.deleted = action.meta.arg;
    });
    builder.addCase(workSchedulingDeleteByIds.rejected, (state) => {
      state.loading.deleted = [];
    });
    builder.addCase(workSchedulingDeleteByIds.fulfilled, (state) => {
      state.loading.deleted = [];
    });
  }
});

export default workSchedulingPercentageSlice.reducer;
