import { notification, Typography } from "antd";
import { FMPayResponse } from "fp/store/type";
export class HttpClient {
  private static BaseURI: string = process.env.REACT_APP_API_URL || "";

  public static SetBaseURI(uri: string) {
    HttpClient.BaseURI = uri;
    sessionStorage.setItem("base.uri", uri);
  }

  public static GetBaseURI(): string {
    if (!HttpClient.BaseURI) {
      const uri = sessionStorage.getItem("base.uri");
      if (uri) {
        HttpClient.BaseURI = uri;
      }
    }

    return HttpClient.BaseURI;
  }

  private static ValidMessage(result: any): void {
    try {
      if (!(result instanceof Object) || !Object.prototype.hasOwnProperty.call(result, "success")) {
        return;
      }

      const r = result as FMPayResponse<any>;
      if (!r.success) {
        notification.warning({
          message: "接口异常",
          description: r.message,
          placement: "topRight"
        });
      }
    } catch (e) {}
  }

  private static ValidResponseStatus(response: Response): void {
    if (response.status >= 500) {
      notification.error({
        message: "服务端错误",
        description: "可能原因：服务器异常、请求参数错误，请联系系统管理员。",
        placement: "topRight"
      });
      return;
    }

    if (response.status >= 400) {
      notification.error({
        message: "客户端错误",
        description: "可能原因：登录失效、没有访问权限、请求参数异常",
        placement: "topRight"
      });
      return;
    }
  }

  public static async Post<T>(url: string, data: any = null): Promise<T> {
    const result = await window.fetch(`${HttpClient.BaseURI}${url}`, {
      headers: { "Content-Type": "application/json; charset=utf8" },
      credentials: "include",
      body: JSON.stringify(data),
      method: "POST"
    });
    HttpClient.ValidResponseStatus(result);
    const r = await (<T>result.json());
    HttpClient.ValidMessage(r);
    return r;
  }

  public static async Get<T>(url: string): Promise<T> {
    const result = await window.fetch(`${HttpClient.BaseURI}${url}`, {
      credentials: "include"
    });
    HttpClient.ValidResponseStatus(result);
    const r = await result.json();
    HttpClient.ValidMessage(r);
    return r;
  }

  public static async Delete<T>(url: string, data: any = null): Promise<T> {
    const result = await window.fetch(`${HttpClient.BaseURI}${url}`, {
      headers: { "Content-Type": "application/json; charset=utf8" },
      credentials: "include",
      body: JSON.stringify(data),
      method: "DELETE"
    });
    HttpClient.ValidResponseStatus(result);
    const r = await (<T>result.json());
    HttpClient.ValidMessage(r);
    return r;
  }
}

if (sessionStorage.getItem("base.uri")) {
  const uri = sessionStorage.getItem("base.uri");
  if (uri) {
    HttpClient.SetBaseURI(uri);
  }
}
