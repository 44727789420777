import { FMPayPaginationQuery, FMPayResponse, FMPayResponsePagination } from "fp/store/type";
import {
  CreateDTO,
  FMPayOrderModel,
  PaginationQuery,
  SumTotalByUserWorkSchedulingResultDTO,
  UpdateByStaffDTO,
  UpdateDTO
} from "fp/modules/order/type";
import { HttpClient } from "fp/store/HttpClient";

export class Service {
  public static async findById(id: string): Promise<FMPayResponse<FMPayOrderModel>> {
    return await HttpClient.Get(`/v1/order/${id}`);
  }

  public static async updateByStaff(
    model: UpdateByStaffDTO
  ): Promise<FMPayResponse<FMPayOrderModel>> {
    return await HttpClient.Post(`/v1/order/update/by/staff`, model);
  }

  public static async create(dto: CreateDTO): Promise<FMPayResponse<FMPayOrderModel>> {
    return await HttpClient.Post(`/v1/order/add`, dto);
  }

  public static async nullify(dto: { id: string }): Promise<FMPayResponse<FMPayOrderModel>> {
    return await HttpClient.Post(`/v1/order/nullify`, dto);
  }

  public static async resetStatus(dto: { id: string }): Promise<FMPayResponse<FMPayOrderModel>> {
    return await HttpClient.Post(`/v1/order/reset/status`, dto);
  }

  public static async sumByWorkSchedulingOperation(dto: {
    workSchedulingOperationId: string;
  }): Promise<FMPayResponse<SumTotalByUserWorkSchedulingResultDTO[]>> {
    return await HttpClient.Post(`/v1/order/sum/by/work/scheduling/operation`, dto);
  }

  public static async pagination(
    query: FMPayPaginationQuery<Partial<PaginationQuery>>
  ): Promise<FMPayResponsePagination<FMPayOrderModel>> {
    return await HttpClient.Post(`/v1/order/pagination`, query);
  }
  public static async update(model: UpdateDTO): Promise<FMPayResponse<FMPayOrderModel>> {
    return await HttpClient.Post(`/v1/order/update`, model);
  }
}
