import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Service } from "fp/modules/media-account/Service";
import { CreateDTO, FMPayMediaAccountModel, PaginationQuery } from "fp/modules/media-account/type";
import { FMPayPagination, FMPayPaginationQuery } from "fp/store/type";

export const mediaAccountFindById = createAsyncThunk<FMPayMediaAccountModel, string>(
  "media.account/find.by.id",
  async (id: string) => {
    const resp = await Service.findById(id);
    return resp.data;
  }
);

export const mediaAccountDeleteByIds = createAsyncThunk<boolean, string[]>(
  "media.account/delete.by.ids",
  async (ids: string[]) => {
    const resp = await Service.deleteByIds(ids);
    return resp.data;
  }
);

export const mediaAccountPagination = createAsyncThunk<
  FMPayPagination<FMPayMediaAccountModel>,
  FMPayPaginationQuery<Partial<PaginationQuery>>
>("media.account/pagination", async (query: FMPayPaginationQuery<Partial<PaginationQuery>>) => {
  const resp = await Service.pagination(query);
  return resp.data;
});

export const mediaAccountUpdate = createAsyncThunk<FMPayMediaAccountModel, FMPayMediaAccountModel>(
  "media.account/update",
  async (model: FMPayMediaAccountModel) => {
    const resp = await Service.update(model);
    return resp.data;
  }
);

export const mediaAccountCreate = createAsyncThunk<FMPayMediaAccountModel, CreateDTO>(
  "media.account/create",
  async (dto: CreateDTO) => {
    const resp = await Service.create(dto);
    return resp.data;
  }
);

export interface MediaAccountState {
  model: FMPayMediaAccountModel | null;
  models: FMPayPagination<FMPayMediaAccountModel>;
  loading: {
    findById: boolean;
    deleted: string[];
    pagination: boolean;
    update: string;
    create: boolean;
  };
}

const InitialState: MediaAccountState = {
  model: null,
  models: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  loading: {
    findById: false,
    deleted: [],
    pagination: false,
    update: "",
    create: false
  }
};

export const mediaAccountSlice = createSlice({
  name: "mediaAccount",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(mediaAccountFindById.pending, (state) => {
      state.loading.findById = true;
    });
    builder.addCase(mediaAccountFindById.rejected, (state) => {
      state.loading.findById = false;
    });
    builder.addCase(mediaAccountFindById.fulfilled, (state, action) => {
      state.loading.findById = false;
      state.model = action.payload;
    });

    builder.addCase(mediaAccountDeleteByIds.pending, (state, action) => {
      state.loading.deleted = action.meta.arg;
    });
    builder.addCase(mediaAccountDeleteByIds.rejected, (state) => {
      state.loading.deleted = [];
    });
    builder.addCase(mediaAccountDeleteByIds.fulfilled, (state) => {
      state.loading.deleted = [];
    });

    builder.addCase(mediaAccountUpdate.pending, (state, action) => {
      state.loading.update = action.meta.arg.id;
    });
    builder.addCase(mediaAccountUpdate.rejected, (state) => {
      state.loading.update = "";
    });
    builder.addCase(mediaAccountUpdate.fulfilled, (state, action) => {
      state.loading.update = "";
      state.model = action.payload;
    });

    builder.addCase(mediaAccountPagination.pending, (state) => {
      state.loading.pagination = true;
    });
    builder.addCase(mediaAccountPagination.rejected, (state) => {
      state.loading.pagination = false;
    });
    builder.addCase(mediaAccountPagination.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.models = action.payload;
    });

    builder.addCase(mediaAccountCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(mediaAccountCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(mediaAccountCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });
  }
});

export default mediaAccountSlice.reducer;
