import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Service } from "fp/modules/operation-record/Service";
import {
  CreateDTO,
  FMPayOperationRecordModel,
  PaginationQuery
} from "fp/modules/operation-record/type";
import { FMPayPagination, FMPayPaginationQuery } from "fp/store/type";

export const operationRecordFindById = createAsyncThunk<FMPayOperationRecordModel, string>(
  "operation.record/find.by.id",
  async (id: string) => {
    const resp = await Service.findById(id);
    return resp.data;
  }
);

export const operationRecordDeleteByIds = createAsyncThunk<boolean, string[]>(
  "operation.record/delete.by.ids",
  async (ids: string[]) => {
    const resp = await Service.deleteByIds(ids);
    return resp.data;
  }
);

export const operationRecordPagination = createAsyncThunk<
  FMPayPagination<FMPayOperationRecordModel>,
  FMPayPaginationQuery<Partial<PaginationQuery>>
>("operation.record/pagination", async (query: FMPayPaginationQuery<Partial<PaginationQuery>>) => {
  const resp = await Service.pagination(query);
  return resp.data;
});

export const operationRecordUpdate = createAsyncThunk<
  FMPayOperationRecordModel,
  FMPayOperationRecordModel
>("operation.record/update", async (model: FMPayOperationRecordModel) => {
  const resp = await Service.update(model);
  return resp.data;
});

export const operationRecordCreate = createAsyncThunk<FMPayOperationRecordModel, CreateDTO>(
  "operation.record/create",
  async (dto: CreateDTO) => {
    const resp = await Service.create(dto);
    return resp.data;
  }
);

export interface OperationRecordState {
  model: FMPayOperationRecordModel | null;
  models: FMPayPagination<FMPayOperationRecordModel>;
  loading: {
    findById: boolean;
    deleted: string[];
    pagination: boolean;
    update: string;
    create: boolean;
  };
}

const InitialState: OperationRecordState = {
  model: null,
  models: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  loading: {
    findById: false,
    deleted: [],
    pagination: false,
    update: "",
    create: false
  }
};

export const operationRecordSlice = createSlice({
  name: "operationRecord",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(operationRecordFindById.pending, (state) => {
      state.loading.findById = true;
    });
    builder.addCase(operationRecordFindById.rejected, (state) => {
      state.loading.findById = false;
    });
    builder.addCase(operationRecordFindById.fulfilled, (state, action) => {
      state.loading.findById = false;
      state.model = action.payload;
    });

    builder.addCase(operationRecordDeleteByIds.pending, (state, action) => {
      state.loading.deleted = action.meta.arg;
    });
    builder.addCase(operationRecordDeleteByIds.rejected, (state) => {
      state.loading.deleted = [];
    });
    builder.addCase(operationRecordDeleteByIds.fulfilled, (state) => {
      state.loading.deleted = [];
    });

    builder.addCase(operationRecordUpdate.pending, (state, action) => {
      state.loading.update = action.meta.arg.id;
    });
    builder.addCase(operationRecordUpdate.rejected, (state) => {
      state.loading.update = "";
    });
    builder.addCase(operationRecordUpdate.fulfilled, (state, action) => {
      state.loading.update = "";
      state.model = action.payload;
    });

    builder.addCase(operationRecordPagination.pending, (state) => {
      state.loading.pagination = true;
    });
    builder.addCase(operationRecordPagination.rejected, (state) => {
      state.loading.pagination = false;
    });
    builder.addCase(operationRecordPagination.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.models = action.payload;
    });

    builder.addCase(operationRecordCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(operationRecordCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(operationRecordCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });
  }
});

export default operationRecordSlice.reducer;
