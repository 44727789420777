import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Service } from "fp/modules/work-scheduling-operation/Service";
import { Service as WorkSchedulingService } from "fp/modules/work-scheduling/Service";
import { Service as MediaAccountService } from "fp/modules/media-account/Service";
import { Services as StoreServices } from "fp/store/Services";
import {
  FMPayMediaAccountModel,
  PaginationQuery as MediaAccountPaginationQuery
} from "fp/modules/media-account/type";
import { PaginationQuery as WorkSchedulingPaginationQuery } from "fp/modules/work-scheduling/type";
import {
  CreateDTO,
  FMPayWorkSchedulingOperationModel,
  PaginationQuery,
  UpdateDTO
} from "fp/modules/work-scheduling-operation/type";
import { FMPayPagination, FMPayPaginationQuery, FMPayUserModel } from "fp/store/type";
import { FMPayWorkSchedulingModel } from "fp/modules/work-scheduling/type";

export const workSchedulingOperationFindById = createAsyncThunk<
  FMPayWorkSchedulingOperationModel,
  string
>("work.scheduling.operation/find.by.id", async (id: string) => {
  const resp = await Service.findById(id);
  return resp.data;
});

export const workSchedulingOperationDeleteByIds = createAsyncThunk<boolean, string[]>(
  "work.scheduling.operation/delete.by.ids",
  async (ids: string[]) => {
    const resp = await Service.deleteByIds(ids);
    return resp.data;
  }
);

export const workSchedulingOperationLockByIds = createAsyncThunk<
  FMPayWorkSchedulingOperationModel,
  string
>("work.scheduling.operation/lock.by.ids", async (id: string) => {
  const resp = await Service.lockById(id);
  return resp.data;
});

export const workSchedulingOperationUpdate = createAsyncThunk<
  FMPayWorkSchedulingOperationModel,
  UpdateDTO
>("work.scheduling.operation/update", async (model: UpdateDTO) => {
  const resp = await Service.update(model);
  return resp.data;
});

export const workSchedulingOperationPagination = createAsyncThunk<
  FMPayPagination<FMPayWorkSchedulingOperationModel>,
  FMPayPaginationQuery<Partial<PaginationQuery>>
>(
  "work.scheduling.operation/pagination",
  async (query: FMPayPaginationQuery<Partial<PaginationQuery>>) => {
    const resp = await Service.pagination(query);
    return resp.data;
  }
);

export const workSchedulingOperationCreate = createAsyncThunk<
  FMPayWorkSchedulingOperationModel,
  CreateDTO
>("work.scheduling.operation/create", async (dto: CreateDTO) => {
  const resp = await Service.create(dto);
  return resp.data;
});

export const workSchedulingOperationQueryWorkScheduling = createAsyncThunk<
  FMPayPagination<FMPayWorkSchedulingModel>,
  FMPayPaginationQuery<Partial<WorkSchedulingPaginationQuery>>
>("work.scheduling.operation/query.work.scheduling", async (query) => {
  const resp = await WorkSchedulingService.pagination(query);
  return resp.data;
});

export const workSchedulingOperationQueryMediaAccount = createAsyncThunk<
  FMPayPagination<FMPayMediaAccountModel>,
  FMPayPaginationQuery<Partial<MediaAccountPaginationQuery>>
>("work.scheduling.operation/query.media.account", async (query) => {
  const resp = await MediaAccountService.pagination(query);
  return resp.data;
});

export const workSchedulingOperationQueryUser = createAsyncThunk<
  FMPayPagination<FMPayUserModel>,
  FMPayPaginationQuery<string>
>("work.scheduling.operation/query.user", async (query) => {
  const resp = await StoreServices.User.pagination(query);
  return resp.data;
});

export interface WorkSchedulingOperationState {
  model: FMPayWorkSchedulingOperationModel | null;
  models: FMPayPagination<FMPayWorkSchedulingOperationModel>;
  workScheduling: FMPayPagination<FMPayWorkSchedulingModel>;
  mediaAccount: FMPayPagination<FMPayMediaAccountModel>;
  user: FMPayPagination<FMPayUserModel>;
  loading: {
    findById: boolean;
    deleted: string[];
    lock: string;
    pagination: boolean;
    today: boolean;
    update: string;
    create: boolean;

    workScheduling: boolean;
    mediaAccount: boolean;
    user: boolean;
  };
}

const InitialState: WorkSchedulingOperationState = {
  model: null,
  models: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  workScheduling: {
    total: 0,
    size: 999,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  mediaAccount: {
    total: 0,
    size: 999,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  user: {
    total: 0,
    size: 999,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  loading: {
    findById: false,
    deleted: [],
    lock: "",
    pagination: false,
    today: false,
    update: "",
    create: false,
    workScheduling: false,
    mediaAccount: false,
    user: false
  }
};

export const workSchedulingOperationSlice = createSlice({
  name: "workSchedulingOperation",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(workSchedulingOperationFindById.pending, (state) => {
      state.loading.findById = true;
    });
    builder.addCase(workSchedulingOperationFindById.rejected, (state) => {
      state.loading.findById = false;
    });
    builder.addCase(workSchedulingOperationFindById.fulfilled, (state, action) => {
      state.loading.findById = false;
      state.model = action.payload;
    });

    builder.addCase(workSchedulingOperationDeleteByIds.pending, (state, action) => {
      state.loading.deleted = action.meta.arg;
    });
    builder.addCase(workSchedulingOperationDeleteByIds.rejected, (state) => {
      state.loading.deleted = [];
    });
    builder.addCase(workSchedulingOperationDeleteByIds.fulfilled, (state) => {
      state.loading.deleted = [];
    });

    builder.addCase(workSchedulingOperationLockByIds.pending, (state, action) => {
      state.loading.lock = action.meta.arg;
    });
    builder.addCase(workSchedulingOperationLockByIds.rejected, (state) => {
      state.loading.lock = "";
    });
    builder.addCase(workSchedulingOperationLockByIds.fulfilled, (state) => {
      state.loading.lock = "";
    });

    builder.addCase(workSchedulingOperationUpdate.pending, (state, action) => {
      state.loading.update = action.meta.arg.id;
    });
    builder.addCase(workSchedulingOperationUpdate.rejected, (state) => {
      state.loading.update = "";
    });
    builder.addCase(workSchedulingOperationUpdate.fulfilled, (state, action) => {
      state.loading.update = "";
      state.model = action.payload;
    });

    builder.addCase(workSchedulingOperationPagination.pending, (state) => {
      state.loading.pagination = true;
    });
    builder.addCase(workSchedulingOperationPagination.rejected, (state) => {
      state.loading.pagination = false;
    });
    builder.addCase(workSchedulingOperationPagination.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.models = action.payload;
    });

    builder.addCase(workSchedulingOperationCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(workSchedulingOperationCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(workSchedulingOperationCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });

    builder.addCase(workSchedulingOperationQueryWorkScheduling.pending, (state) => {
      state.loading.workScheduling = true;
    });
    builder.addCase(workSchedulingOperationQueryWorkScheduling.rejected, (state) => {
      state.loading.workScheduling = false;
    });
    builder.addCase(workSchedulingOperationQueryWorkScheduling.fulfilled, (state, action) => {
      state.loading.workScheduling = false;
      state.workScheduling = action.payload;
    });

    builder.addCase(workSchedulingOperationQueryMediaAccount.pending, (state) => {
      state.loading.mediaAccount = true;
    });
    builder.addCase(workSchedulingOperationQueryMediaAccount.rejected, (state) => {
      state.loading.mediaAccount = false;
    });
    builder.addCase(workSchedulingOperationQueryMediaAccount.fulfilled, (state, action) => {
      state.loading.mediaAccount = true;
      state.mediaAccount = action.payload;
    });

    builder.addCase(workSchedulingOperationQueryUser.pending, (state) => {
      state.loading.user = true;
    });
    builder.addCase(workSchedulingOperationQueryUser.rejected, (state) => {
      state.loading.user = false;
    });
    builder.addCase(workSchedulingOperationQueryUser.fulfilled, (state, action) => {
      state.loading.user = false;
      state.user = action.payload;
    });
  }
});

export default workSchedulingOperationSlice.reducer;
