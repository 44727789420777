import { Button, Result } from "antd";
import React from "react";

export const Exception404: React.FC<{ redirect?: string }> = (props) => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="未找到该页面，请检查访问地址是否正确！"
      extra={
        <Button
          type="primary"
          onClick={() => {
            window.location.href = props.redirect || "/";
          }}
        >
          回到首页
        </Button>
      }
    />
  );
};
