import { FMPayPaginationQuery, FMPayResponse, FMPayResponsePagination } from "fp/store/type";
import {
  FMPayWorkSchedulingPercentageModel,
  PaginationQuery
} from "fp/modules/work-scheduling-percentage/type";
import { HttpClient } from "fp/store/HttpClient";

export class Service {
  public static async findById(
    id: string
  ): Promise<FMPayResponse<FMPayWorkSchedulingPercentageModel>> {
    return await HttpClient.Get(`/v1/work/scheduling/percentage/${id}`);
  }

  public static async deleteByIds(ids: string[]): Promise<FMPayResponse<boolean>> {
    return await HttpClient.Post(`/v1/work/scheduling/percentage/deleted`, { ids });
  }

  public static async pagination(
    query: FMPayPaginationQuery<Partial<PaginationQuery>>
  ): Promise<FMPayResponsePagination<FMPayWorkSchedulingPercentageModel>> {
    return await HttpClient.Post(`/v1/work/scheduling/percentage/pagination`, query);
  }

  public static async currentWorkScheduling(): Promise<
    FMPayResponse<FMPayWorkSchedulingPercentageModel>
  > {
    return await HttpClient.Get(`/v1/work/scheduling/percentage/current/work/scheduling`);
  }

  public static async update(
    model: FMPayWorkSchedulingPercentageModel
  ): Promise<FMPayResponse<FMPayWorkSchedulingPercentageModel>> {
    return await HttpClient.Post(`/v1/work/scheduling/percentage/update`, model);
  }
}
