import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { FMPayResponse, FMPayResponsePagination, FMPaySecurityCodeModel, SecurityCodeStatus } from "fp/store/type";
import { Services } from "fp/store/Services";

export const securityCodeCreate = createAsyncThunk<FMPaySecurityCodeModel, { account: string }>(
  "security.code/create",
  async (p, thunkApi) => {
    const resp = await Services.FMPaySecurityCode.create(p.account);
    if (resp.success) {
      const state: SecurityCodeState = (thunkApi.getState() as any).securityCode;
      thunkApi.dispatch(
        securityCodePagination({
          current: state.pagination.data.current,
          size: state.pagination.data.size,
        })
      );
    }

    return resp.data;
  }
);

export const securityCodeNullify = createAsyncThunk<FMPayResponse<FMPaySecurityCodeModel>, { id: string }>(
  "security.code/nullify",
  async (p, thunkApi) => {
    const resp = await Services.FMPaySecurityCode.nullify(p.id);
    if (resp.success) {
      const state: SecurityCodeState = (thunkApi.getState() as any).securityCode;
      thunkApi.dispatch(
        securityCodePagination({
          current: state.pagination.data.current,
          size: state.pagination.data.size,
        })
      );
    }
    return resp;
  }
);

export const securityCodePagination = createAsyncThunk<
  FMPayResponsePagination<FMPaySecurityCodeModel>,
  { current: number; size: number }
>("security.code/pagination", async (p) => {
  const resp = await Services.FMPaySecurityCode.pagination(p.current, p.size);
  return resp;
});

export const securityCodePaginationByStatus = createAsyncThunk<
  FMPayResponsePagination<FMPaySecurityCodeModel>,
  { status: SecurityCodeStatus; current: number; size: number }
>("security.code/pagination.by.status", async (p) => {
  const resp = await Services.FMPaySecurityCode.paginationByStatus(p.status, p.current, p.size);
  return resp;
});

export interface SecurityCodeState {
  model: FMPaySecurityCodeModel | null;
  pagination: FMPayResponsePagination<FMPaySecurityCodeModel>;
  loading: {
    create: boolean;
    pagination: boolean;
    nullifyId: string | null;
  };
}

const InitialState: SecurityCodeState = {
  model: null,
  pagination: {
    success: false,
    message: "",
    status: "",
    data: {
      total: 0,
      current: 1,
      size: 10,
      pages: 0,
      latest: false,
      data: [],
    },
  },
  loading: {
    create: false,
    pagination: false,
    nullifyId: null,
  },
};

export const bankSlice = createSlice({
  name: "securityCode",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(securityCodeCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(securityCodeCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(securityCodeCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });

    builder.addCase(securityCodePagination.pending, (state) => {
      state.loading.pagination = true;
    });
    builder.addCase(securityCodePagination.rejected, (state) => {
      state.loading.pagination = false;
    });
    builder.addCase(securityCodePagination.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.pagination = action.payload;
    });

    builder.addCase(securityCodeNullify.pending, (state, action) => {
      state.loading.nullifyId = action.meta.arg.id;
    });
    builder.addCase(securityCodeNullify.rejected, (state, action) => {
      state.loading.nullifyId = "";
    });
    builder.addCase(securityCodeNullify.fulfilled, (state, action) => {
      state.loading.nullifyId = "";
    });
  },
});

export default bankSlice.reducer;
