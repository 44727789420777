import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Service } from "fp/modules/recharge-record/Service";
import {
  CreateDTO,
  FMPayRechargeRecordModel,
  PaginationQuery
} from "fp/modules/recharge-record/type";
import { FMPayPagination, FMPayPaginationQuery } from "fp/store/type";

export const rechargeRecordFindById = createAsyncThunk<FMPayRechargeRecordModel, string>(
  "recharge.record/find.by.id",
  async (id: string) => {
    const resp = await Service.findById(id);
    return resp.data;
  }
);

export const rechargeRecordDeleteByIds = createAsyncThunk<boolean, string[]>(
  "recharge.record/delete.by.ids",
  async (ids: string[]) => {
    const resp = await Service.deleteByIds(ids);
    return resp.data;
  }
);

export const rechargeRecordPagination = createAsyncThunk<
  FMPayPagination<FMPayRechargeRecordModel>,
  FMPayPaginationQuery<Partial<PaginationQuery>>
>("recharge.record/pagination", async (query: FMPayPaginationQuery<Partial<PaginationQuery>>) => {
  const resp = await Service.pagination(query);
  return resp.data;
});

export const rechargeRecordUpdate = createAsyncThunk<
  FMPayRechargeRecordModel,
  FMPayRechargeRecordModel
>("recharge.record/update", async (model: FMPayRechargeRecordModel) => {
  const resp = await Service.update(model);
  return resp.data;
});

export const rechargeRecordCreate = createAsyncThunk<FMPayRechargeRecordModel, CreateDTO>(
  "recharge.record/create",
  async (dto: CreateDTO) => {
    const resp = await Service.create(dto);
    return resp.data;
  }
);

export interface RechargeRecordState {
  model: FMPayRechargeRecordModel | null;
  models: FMPayPagination<FMPayRechargeRecordModel>;
  loading: {
    findById: boolean;
    deleted: string[];
    pagination: boolean;
    update: string;
    create: boolean;
  };
}

const InitialState: RechargeRecordState = {
  model: null,
  models: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  loading: {
    findById: false,
    deleted: [],
    pagination: false,
    update: "",
    create: false
  }
};

export const rechargeRecordSlice = createSlice({
  name: "rechargeRecord",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(rechargeRecordFindById.pending, (state) => {
      state.loading.findById = true;
    });
    builder.addCase(rechargeRecordFindById.rejected, (state) => {
      state.loading.findById = false;
    });
    builder.addCase(rechargeRecordFindById.fulfilled, (state, action) => {
      state.loading.findById = false;
      state.model = action.payload;
    });

    builder.addCase(rechargeRecordDeleteByIds.pending, (state, action) => {
      state.loading.deleted = action.meta.arg;
    });
    builder.addCase(rechargeRecordDeleteByIds.rejected, (state) => {
      state.loading.deleted = [];
    });
    builder.addCase(rechargeRecordDeleteByIds.fulfilled, (state) => {
      state.loading.deleted = [];
    });

    builder.addCase(rechargeRecordUpdate.pending, (state, action) => {
      state.loading.update = action.meta.arg.id;
    });
    builder.addCase(rechargeRecordUpdate.rejected, (state) => {
      state.loading.update = "";
    });
    builder.addCase(rechargeRecordUpdate.fulfilled, (state, action) => {
      state.loading.update = "";
      state.model = action.payload;
    });

    builder.addCase(rechargeRecordPagination.pending, (state) => {
      state.loading.pagination = true;
    });
    builder.addCase(rechargeRecordPagination.rejected, (state) => {
      state.loading.pagination = false;
    });
    builder.addCase(rechargeRecordPagination.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.models = action.payload;
    });

    builder.addCase(rechargeRecordCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(rechargeRecordCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(rechargeRecordCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });
  }
});

export default rechargeRecordSlice.reducer;
