import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Service } from "fp/modules/transfer-error-record/Service";
import {
  CreateDTO,
  FMPayTransferErrorRecordModel,
  PaginationQuery
} from "fp/modules/transfer-error-record/type";
import { FMPayPagination, FMPayPaginationQuery } from "fp/store/type";

export const transferErrorRecordFindById = createAsyncThunk<FMPayTransferErrorRecordModel, string>(
  "transfer.error.record/find.by.id",
  async (id: string) => {
    const resp = await Service.findById(id);
    return resp.data;
  }
);

export const transferErrorRecordDeleteByIds = createAsyncThunk<boolean, string[]>(
  "transfer.error.record/delete.by.ids",
  async (ids: string[]) => {
    const resp = await Service.deleteByIds(ids);
    return resp.data;
  }
);

export const transferErrorRecordPagination = createAsyncThunk<
  FMPayPagination<FMPayTransferErrorRecordModel>,
  FMPayPaginationQuery<Partial<PaginationQuery>>
>(
  "transfer.error.record/pagination",
  async (query: FMPayPaginationQuery<Partial<PaginationQuery>>) => {
    const resp = await Service.pagination(query);
    return resp.data;
  }
);

export const transferErrorRecordUpdate = createAsyncThunk<
  FMPayTransferErrorRecordModel,
  FMPayTransferErrorRecordModel
>("transfer.error.record/update", async (model: FMPayTransferErrorRecordModel) => {
  const resp = await Service.update(model);
  return resp.data;
});

export const transferErrorRecordCreate = createAsyncThunk<FMPayTransferErrorRecordModel, CreateDTO>(
  "transfer.error.record/create",
  async (dto: CreateDTO) => {
    const resp = await Service.create(dto);
    return resp.data;
  }
);

export interface TransferErrorRecordState {
  model: FMPayTransferErrorRecordModel | null;
  models: FMPayPagination<FMPayTransferErrorRecordModel>;
  loading: {
    findById: boolean;
    deleted: string[];
    pagination: boolean;
    update: string;
    create: boolean;
  };
}

const InitialState: TransferErrorRecordState = {
  model: null,
  models: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  loading: {
    findById: false,
    deleted: [],
    pagination: false,
    update: "",
    create: false
  }
};

export const transferErrorRecordSlice = createSlice({
  name: "transferErrorRecord",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(transferErrorRecordFindById.pending, (state) => {
      state.loading.findById = true;
    });
    builder.addCase(transferErrorRecordFindById.rejected, (state) => {
      state.loading.findById = false;
    });
    builder.addCase(transferErrorRecordFindById.fulfilled, (state, action) => {
      state.loading.findById = false;
      state.model = action.payload;
    });

    builder.addCase(transferErrorRecordDeleteByIds.pending, (state, action) => {
      state.loading.deleted = action.meta.arg;
    });
    builder.addCase(transferErrorRecordDeleteByIds.rejected, (state) => {
      state.loading.deleted = [];
    });
    builder.addCase(transferErrorRecordDeleteByIds.fulfilled, (state) => {
      state.loading.deleted = [];
    });

    builder.addCase(transferErrorRecordUpdate.pending, (state, action) => {
      state.loading.update = action.meta.arg.id;
    });
    builder.addCase(transferErrorRecordUpdate.rejected, (state) => {
      state.loading.update = "";
    });
    builder.addCase(transferErrorRecordUpdate.fulfilled, (state, action) => {
      state.loading.update = "";
      state.model = action.payload;
    });

    builder.addCase(transferErrorRecordPagination.pending, (state) => {
      state.loading.pagination = true;
    });
    builder.addCase(transferErrorRecordPagination.rejected, (state) => {
      state.loading.pagination = false;
    });
    builder.addCase(transferErrorRecordPagination.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.models = action.payload;
    });

    builder.addCase(transferErrorRecordCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(transferErrorRecordCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(transferErrorRecordCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });
  }
});

export default transferErrorRecordSlice.reducer;
