import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Services } from "fp/store/Services";
import {
  FMPayGroupModel,
  FMPayOrderUpdateStatusDTO,
  FMPayPagination,
  FMPayPaginationQuery,
  FMPayResponse,
  FMPayResponsePagination,
  OrderPaginationQuery,
  OrderStatus,
  PaymentBankModel,
  FMPayPaymentOrderModel,
  OrderPaginationDTO
} from "fp/store/type";

export const paymentOrderGetOrders = createAsyncThunk<
  FMPayResponsePagination<FMPayPaymentOrderModel>,
  FMPayPaginationQuery<Partial<OrderPaginationQuery>>
>("payment.order/get.orders", async (query) => {
  return await Services.FMPayOrder.pagination(query);
});

export const paymentOrderGetBanks = createAsyncThunk<PaymentBankModel[]>(
  "payment.order/get.banks",
  async () => {
    return Services.FMPayBank.items();
  }
);

export const paymentOrderGetGroups = createAsyncThunk<FMPayPagination<FMPayGroupModel>>(
  "payment.order/get.groups",
  async () => {
    return Services.FMPayGroup.items();
  }
);

export const paymentOrderGetOrderById = createAsyncThunk<FMPayPaymentOrderModel | null, string>(
  "payment.order/update.order",
  async (id) => {
    const resp = await Services.FMPayOrder.getById(id);
    return resp.data;
  }
);

export const paymentOrderUpdateStatus = createAsyncThunk<
  FMPayResponse<FMPayPaymentOrderModel>,
  FMPayOrderUpdateStatusDTO
>("payment.order/update.status", async (dto) => {
  return Services.FMPayOrder.updateStatus(dto);
});

export interface PaymentOrderState {
  loading: {
    orders: boolean;
    banks: boolean;
    groups: boolean;
    updated: string;
    updateStatus: string;
  };
  orders: FMPayPagination<FMPayPaymentOrderModel>;
  banks: PaymentBankModel[];
  groups: FMPayGroupModel[];
  selectedOrder: FMPayPaymentOrderModel | null;
}

const InitialState: PaymentOrderState = {
  loading: {
    orders: false,
    banks: false,
    groups: false,
    updated: "",
    updateStatus: ""
  },
  orders: {
    current: 1,
    size: 6,
    total: 0,
    pages: 0,
    latest: false,
    data: []
  },
  banks: [],
  groups: [],
  selectedOrder: null
};

const OrderStatusValueMap: { [key: string]: number } = {
  [OrderStatus.NORMAL]: 0,
  [OrderStatus.IN_PAYMENT]: 1,
  [OrderStatus.PAID]: 2,
  [OrderStatus.PAYMENT_FAILED]: 3,
  [OrderStatus.PAUSE]: 4,
  [OrderStatus.NULLIFY]: 5
};

export const paymentOrderSlice = createSlice({
  name: "paymentOrder",
  initialState: { ...InitialState },
  reducers: {
    setPagination(state, action: PayloadAction<{ current: number; size: number }>) {
      state.orders.current = action.payload.current;
      state.orders.size = action.payload.size;
    },
    setSelectedOrder(state, action: PayloadAction<FMPayPaymentOrderModel | null>) {
      state.selectedOrder = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(paymentOrderGetOrders.pending, (state) => {
      state.loading.orders = true;
    });
    builder.addCase(paymentOrderGetOrders.rejected, (state) => {
      state.loading.orders = false;
    });
    builder.addCase(paymentOrderGetOrders.fulfilled, (state, action) => {
      state.loading.orders = false;
      if (action.payload.success) {
        state.orders = action.payload.data;
      }
    });

    builder.addCase(paymentOrderGetBanks.pending, (state) => {
      state.loading.banks = true;
    });
    builder.addCase(paymentOrderGetBanks.rejected, (state) => {
      state.loading.banks = false;
    });
    builder.addCase(paymentOrderGetBanks.fulfilled, (state, action) => {
      state.loading.banks = false;
      state.banks = action.payload;
    });

    builder.addCase(paymentOrderGetGroups.pending, (state) => {
      state.loading.groups = true;
    });
    builder.addCase(paymentOrderGetGroups.rejected, (state) => {
      state.loading.groups = false;
    });
    builder.addCase(paymentOrderGetGroups.fulfilled, (state, action) => {
      state.loading.groups = false;
      state.groups = action.payload.data;
    });

    builder.addCase(paymentOrderGetOrderById.pending, (state, action) => {
      state.loading.updated = action.meta.arg;
    });
    builder.addCase(paymentOrderGetOrderById.rejected, (state) => {
      state.loading.updated = "";
      state.selectedOrder = null;
    });
    builder.addCase(paymentOrderGetOrderById.fulfilled, (state, action) => {
      state.loading.updated = "";
      const order = action.payload;
      state.selectedOrder = order;

      if (order === null) {
        return;
      }

      state.orders.data = state.orders.data.map<FMPayPaymentOrderModel>((r) => {
        if (r.id === order.id) {
          return order;
        }
        return r;
      });
    });

    builder.addCase(paymentOrderUpdateStatus.pending, (state, action) => {
      state.loading.updateStatus = action.meta.arg.id;
    });
    builder.addCase(paymentOrderUpdateStatus.rejected, (state) => {
      state.loading.updateStatus = "";
      state.selectedOrder = null;
    });
    builder.addCase(paymentOrderUpdateStatus.fulfilled, (state, action) => {
      state.loading.updateStatus = "";
      if (!action.payload.success) {
        return;
      }

      const order = action.payload.data;
      state.selectedOrder = order;

      if (order === null) {
        return;
      }

      state.orders.data = state.orders.data.map<OrderPaginationDTO>((r) => {
        if (r.id === order.id) {
          return order;
        }
        return r;
      });
    });
  }
});

export default paymentOrderSlice.reducer;

export const { setPagination, setSelectedOrder } = paymentOrderSlice.actions;
